.contact {
    display: flex;
    margin-bottom: -100px;
    background-image: url(../../../public/img/bg/Ellipse-55.png);

    // background-image:linear-gradient(rgba(0, 0, 0, 0.3),rgba(0, 0, 0, 0.3)) , url(../../../public/img/bg/bg-contact.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    // background-position: top;
    .contact__img {
        overflow: hidden;
        @include md {
            display: none;
        }
        .img__wrap {
            img {
                height: 100%;
                width: 100%;
            }
        }
    }
    .contact__content {
        display: flex;
        align-items: center;
        // padding-left: 50px;
        margin: 60px 0;
        // padding-right: 100px;
        @include md {
            margin: 70px 0 0;
            padding-left: 15px ;
        }
    }
}
